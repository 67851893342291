
    import { Vue, Component } from 'vue-property-decorator';
    import UserForm from '@/components/admin/users/UserForm.vue';
    import { User } from '@/interfaces/user/User';
    import { addUser } from '@/api/User';
    import auth from '@/store/auth/auth';

    @Component({
        components: {
            UserForm,
        },
    })
    export default class UserAdd extends Vue {
        private user: User | null = null;

        async addUser() {
            if (!this.user) {
                throw new Error('Error while creating new user.');
            }

            let response = await addUser(this.user);

            if (response && (response.status === 401 || response.status === 422)) {
                await auth.dispatchRefreshToken();

                response = await addUser(this.user);
            }

            if (!response || !(await response.parsedBody)) {
                throw new Error('Error while creating user.');
            }

            return response;
        }

        async dispatchAddUser() {
            if (!this.user?.password) {
                this.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Bitte ein Passwort vergeben.',
                    type: 'is-danger',
                    ariaRole: 'alertdialog',
                    ariaModal: true,
                });
            } else {
                const resp = await this.addUser();

                if (resp.status === 303) {
                    this.$buefy.dialog.alert({
                        title: 'Fehler',
                        message: 'Der Username existiert bereits!',
                        type: 'is-danger',
                        ariaRole: 'alertdialog',
                        ariaModal: true,
                    });
                } else {
                    await this.$router.push({ name: 'userlist' });
                    this.$buefy.toast.open('CRM-Account erfolgreich hinzugefügt.');
                }
            }
        }

        mounted() {
            this.user = {
                _id: '',
                username: '',
                password: '',
                abbr: '',
                email: '',
                groups: {
                    auth: [],
                    voice: [],
                },
            };
        }
    }
